import React, { useState, useEffect } from 'react';

export default function useGetDeviceType() {
    const checkDevieType = () => {
        let screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            setIsMobile(true);
            setIsTablet(false);
            setIsSmallDesktop(false);
            setIsDesktop(false);
        } else if (screenWidth > 768 && screenWidth < 992) {
            setIsMobile(false);
            setIsTablet(true);
            setIsSmallDesktop(false);
            setIsDesktop(false);
        } else if (screenWidth > 992 && screenWidth < 1200) {
            setIsMobile(false);
            setIsTablet(false);
            setIsSmallDesktop(true);
            setIsDesktop(false);
        } else if (screenWidth > 1200) {
            setIsMobile(false);
            setIsTablet(false);
            setIsSmallDesktop(false);
            setIsDesktop(true);
        }
    };

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isSmallDesktop, setIsSmallDesktop] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        // const handlePageResized = () => {
        //   setIsMobile(checkForDevice);
        // };

        window.addEventListener('resize', checkDevieType);
        window.addEventListener('orientationchange', checkDevieType);
        window.addEventListener('load', checkDevieType);
        window.addEventListener('reload', checkDevieType);

        return () => {
            window.removeEventListener('resize', checkDevieType);
            window.removeEventListener('orientationchange', checkDevieType);
            window.removeEventListener('load', checkDevieType);
            window.removeEventListener('reload', checkDevieType);
        };
    }, []);

    return { isMobile, isTablet, isSmallDesktop, isDesktop };
}