import Head from "next/head";
import useGetDeviceType from "../hooks/helpers/useGetDeviceType";
import topImageHome from '../public/images/home-page-assets/phone_app_front.png';
import Link from "next/link";
import UseWindowSize from "../hooks/helpers/useWindowSize";
import { CommonStyle } from "../styles/common-styles";
import Banner from "../components/UI-layout/banner";
import ReactPlayer from "react-player";
import phoneAppMapLeft from '../public/images/home-page-assets/phone_app_map_left.png';
import phoneAppCategoriesRight from '../public/images/home-page-assets/phone_app_categories_right.png';
import team from '../public/images/home-page-assets/side-by-side.png';
import { fleettopContentHomePage, homeContent } from "../utils/wordings";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { CustomerReviewsComponent } from "../components/customer-reviews";
import { DownloadAppFooter } from "../components/download-app-footer";
import { tagLink } from "../utils/lib/events";
import { useRouter } from "next/router";
import Image from "next/image";

const Home = () => {
    const router = useRouter();
    const { isMobile } = useGetDeviceType();
    const screenProps = UseWindowSize();

    const { loaded, error, formCreated } = useHubspotForm({
        portalId: "8798012",
        formId: "8e20075c-7f5f-4540-af62-91e443b06ea1",
        target: '#my-hubspot-form'
    });

    return (

        <div className="container-fluid p-0">
                <Head>
                    <title>Paleo Car Care - Expert Auto Maintenance for Your Peace of Mind</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Discover the best in auto care with Paleo Car Care - Your trusted partner for top-notch car maintenance services." />
                    <meta name="author" content="Paleo Car Care" />
                    <meta property="og:title" content="Paleo Car Care - Expert Auto Maintenance for Your Peace of Mind" />
                    <meta property="og:description" content="Discover the best in auto care with Paleo Car Care - Your trusted partner for top-notch car maintenance services." />
                    <meta property="og:image" itemProp="image" content="https://www.paleocarcare.com" />.
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:url" content="https://www.paleocarcare.com/../../../public/images/left-img.png" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:site_name" content="Paleo Car Care" />
                    <meta name="facebook-domain-verification" content="m4o6pn81efn34qkf6t3fimmjnhbuxs" />
                    <link rel="shortcut icon" href="/favicon.ico" />
                    <link rel="canonical" href="https://www.paleocarcare.com/" hrefLang="en" />

                    <script type="application/ld+json">
                        {`
                            {
                            "@context": "https://schema.org",
                            "@type": "Auto Repair",
                            "name": "paleo Car Care",
                            "url": "https://www.paleocarcare.com",
                            "logo": "https://www.paleocarcare.com/../../../public/images/paleo-logo.svg",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+1-123-456-7890",
                                "contactType": "Service"
                            }
                            }
                        `}
                    </script>
                </Head>

            <CommonStyle>
                <div className="bg-white container-fluid pt-3 px-0">
                    <div className="container">
                        <div className="d-md-flex mt-5 pb-5 ">
                            <div className="caption col-md-7 col-lg-6 d-flex flex-column ">
                                <div className="col-md-10 mt-lg-5 pt-lg-5">
                                    <h1 className="heavy-bold dark-text">Your Car, Our Care.</h1>
                                    <h3 className="grey-text mt-4">Fuel delivery and car services in the palm of your hand!
                                    <br/><br/>Want to ditch the visit to the dirty pumps?  Tired of wasting time due to car maintenance and troubles?
                                    <br/><br/>
                                    Introducing our new fuel delivery and car service technology that saves you both time and money. Give us the opportunity to offer you something that today's gas stations and mechanic shops cannot.
                                    <br/>
                                    <br/>Download the app today, your top rated service advisor awaits!</h3>
                                </div>
                                <ul className="app-stores list-unstyled list-inline mt-3 d-inline-block">
                                    <li className="list-inline-item mr-3">
                                        <Link href={'https://apps.apple.com/us/app/paleo-car-care/id1552154146'}>
                                            <a onClick ={()=>{tagLink ({url:'https://apps.apple.com/us/app/paleo-car-care/id1552154146', item_name:"Doownload From App store"})}} target="_blank">
                                                    <Image
                                                    src="/images/appstore-apple.svg"
                                                    alt="app-store"
                                                    loading="lazy"
                                                    width={140}
                                                    height={80}
                                                    className="ios img-fluid"
                                                    />            
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link href={'https://play.google.com/store/apps/details?id=com.paleo.customer'}>
                                            <a onClick ={()=>{tagLink ({url:'https://play.google.com/store/apps/details?id=com.paleo.customer', item_name:"Doownload From Play store"})}} target="_blank">
                                                <Image
                                                    src="/images/appstore-android.svg"
                                                    alt="google play"
                                                    loading="lazy"
                                                    width={150}
                                                    height={80}
                                                    className="android img-fluid"
                                                 />
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5 col-lg-6 my-4 my-lg-0 text-center d-flex">
                                <div className="col-6" style={screenProps?.windowSize?.width < 768 ? { transform: 'translateY(60px)' } : { transform: 'translate(150px, 60px)', zIndex: 0 }}>
                                    <Image
                                    src={topImageHome.src}
                                    alt='top-banner-front'
                                    loading="lazy"
                                    className="img-fluid rounded"
                                    height={750}
                                    width={550}
                                    layout="responsive"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 py-5 d-flex justify-content-center">
                        <div className="video-wrap text-center col-md-8 mx-3 mx-md-0" >
                            <ReactPlayer height={'100%'} width={'100%'} playing={true} loop={true} muted url='https://firebasestorage.googleapis.com/v0/b/paleo-car-care.appspot.com/o/videos%2Fpaleo_-_website_video_-_presentation%20(1080p).mp4?alt=media&token=7b02ec95-fcf9-4cc3-b231-0c08740f39db' controls={true} />
                        </div>
                    </div>

                    <div className=" bg-light-white">
                        <div className="container py-3 py-md-5 overflow-hidden overflow-lg-visible  col-lg-10 mx-lg-auto">
                            <div className={`d-flex flex-column-reverse flex-md-row mt-5 pb-5 justify-content-center justify-content-lg-between`}>
                                <div className="col-md-5 col-lg-5 my-4 my-lg-0 text-center translate-x-0">
                                    <div style={screenProps?.windowSize?.width > 768 ? { transform: 'translateX(-150px)' } : {}}>
                                        <Image
                                        src={phoneAppCategoriesRight.src}
                                        alt='top-banner'
                                        loading="lazy"
                                        className="img-fluid rounded mt-5 mt-md-0"
                                        layout="responsive"
                                        width={300} 
                                        height={300} 
                                        />
                                    </div>

                                </div>
                                <div className="caption align-items-md-end col-md-8 col-lg-6 d-flex flex-column ">
                                    <div className="col-md-10  mt-lg-5 pt-lg-5">
                                        <p className="text-danger uppercase mb-2">Service & Repair</p>
                                        <h2 className="heavy-bold dark-text">Unprecedented access to our full-service garage at your fingertips.</h2>
                                        <h3 className="grey-text mt-4">We’ll pick up and drop off your car to take care of everything from oil changes to full repairs so you can focus on what matters.</h3>
                                    </div>

                                    <div style={{ width: '83%' }}>
                                        <div className="d-flex  flex-wrap mt-4" style={{ gap: 15 }}>
                                            <Link href={'/garageworks.html'}>
                                                <button onClick={()=>{tagLink ({url:router?.pathname, item_name:"learn about service and repair"})}} className="btn btn-danger py-3">Learn more</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="container py-3 py-md-5 overflow-hidden">
                        <div className={` col-lg-10 mx-lg-auto d-flex flex-column-reverse flex-md-row mt-5 pb-5 justify-content-center justify-content-md-between`}>
                            <div className="caption ms-md-5 col-md-7 col-lg-6 d-flex flex-column ">
                                <div className="col-md-10  mt-lg-5 pt-lg-5">
                                    <p className="text-danger uppercase mb-2">GAS DELIVERY</p>
                                    <h2 className="heavy-bold dark-text">Keeping You Going With a Full Tank</h2>
                                    <h3 className="grey-text mt-4">Gas brought straight to your home, work, or wherever! All for a similar cost of pumping it yourself.</h3>
                                </div>

                                <div style={{ width: '83%' }}>
                                    <div className="d-flex  flex-wrap mt-4" style={{ gap: 15 }}>
                                        <Link href={'/carcreditprogram.html'}>
                                            <button 
                                             onClick={()=>{tagLink ({url:router?.pathname, item_name:"learn about Gas Delivery"})}}
                                             className="btn btn-danger py-3">Learn more
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-5 my-4 my-lg-0 text-center translate-x-25">
                                <div style={screenProps?.windowSize?.width > 768 ? { transform: 'translateX(150px)' } : {}}>
                                    <Image
                                    src={phoneAppMapLeft.src}
                                    alt='top-banner'
                                    loading="lazy"
                                    className="img-fluid rounded"
                                    layout="responsive"
                                    width={300} 
                                    height={300} 
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                    <Banner content={fleettopContentHomePage} />
                    <Banner content={homeContent} />

                    <CustomerReviewsComponent title={"Loved by thousands of Customers like you"} />

                    <div className="bg-light-white">
                        <DownloadAppFooter />
                    </div>
                </div>
            </CommonStyle >
        </div >
    );
};

export default Home;