import ScrollContainer from "react-indiana-drag-scroll"
import { customerReviews } from "../utils/wordings"
import CarouselItem from "./UI-layout/carousal-item"
import { PrimeBlack } from "../utils/constants"

export const CustomerReviewsComponent = ({title}: {title: string}) => {
    return (
        <div className="py-5">
            <div className="caption mx-auto text-center" style={{ maxWidth: 380 }}>
                <h1 className="heavy-bold text-center">
                    {title}
                </h1>
                <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=paleo+car+care&oq=paleo+ca&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7Mg8IARAuGCcYrwEYxwEYigUyBggCEEUYOTIGCAMQRRg7MgYIBBBFGEEyBggFEEUYPDIGCAYQRRg8MgYIBxBFGEHSAQgxODcwajBqNKgCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x865c59b7393a3a3f:0x15c5a01e7d9cbec6,1,,,,">
                    <h3 style={{color: PrimeBlack}}>Google Reviews</h3>
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/paleo-car-care-san-antonio-2?osq=paleo+car+care">
                    <h3 style={{color: PrimeBlack}}>Yelp Reviews</h3>
                </a>
            </div>
            <div className="mt-0 pb-5 ms-md-5">
                <ScrollContainer className="scroll-container d-flex py-5" style={{ gap: 20 }} horizontal={true}>
                    {customerReviews?.map((value, index) => <CarouselItem key={`item-${index}`} review={value} />)}

                </ScrollContainer>
            </div>

        </div>
    )
}