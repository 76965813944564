import React from 'react';
import { MdStar, MdStarHalf, MdStarOutline } from 'react-icons/md';
import StarRatingComponent from 'react-star-rating-component';
import { shorten } from '../../utils/util';
import Link from 'next/link';
import UseWindowSize from '../../hooks/helpers/useWindowSize';
import Image from 'next/image';


function CarouselItem({ review }) {

    const { name, address, description, profileImage, ratings, link } = review;
    const screenProps = UseWindowSize();
    return (
        <div className='bg-white py-md-4 col-md-6 col-lg-4 mx-2 mx-md-auto  box-shadow cursor-pointer scrollable-carousal-item' style={screenProps?.windowSize?.width >= 768 ? { maxHeight: 410, height: 410, width: 380, maxWidth: 380 } : { maxHeight: 410, height: 410, maxWidth: 360, flexShrink: 0 }}>
            <div className="mt-4 position-relative">
                <div className='quote-circular-badge bg-danger'>
                    <img alt='Quote' src="images/quotes.svg" height={20} width={20} />
                </div>
                <div className='mx-auto px-2 px-md-4 overflow-hidden' style={{ height: 270, maxHeight: 270 }}>
                    <div style={{ pointerEvents: 'none' }}>
                        <StarRatingComponent
                            renderStarIcon={(next: number, prev: number, name: any) => {
                                return (prev > next - 1 ? <MdStar color={'#f2cd53'} size={20} /> : <MdStarOutline color={'#f2cd53'} size={20} />)
                            }}
                            renderStarIconHalf={() => {
                                return <MdStarHalf size={20} color={'#f2cd53'} />
                            }}
                            name="technician-rating"
                            starCount={5}
                            value={ratings}
                        />
                    </div>
                    <p>{description}</p>
                </div>
                <hr />
                <div className='px-2 px-lg-4 d-flex align-items-center justify-content-between overflow-hidden' >
                    <div className='col-6 d-flex align-items-center' >
                        <div className="col-3 ">
                            <div className="border carousel-profile border-cover">
                                <div className="circular-image">
                                    <img src={profileImage ? profileImage : 'https://www.paleocarcare.com/assets/images/users/placeholder.png'} alt="profile" className="img-fluid " style={{ height: 45, width: 45, borderRadius: '50%', objectFit: 'cover' }} />
                                </div>
                            </div>

                        </div>
                        <div className='ms-3 ms-lg-3'>
                            <p className='mb-0 bold' style={{ fontSize: 12 }}>{name}</p>
                            <p className='mb-0 grey-text small-text' style={{ fontSize: 12 }}>{address}</p>
                        </div>

                    </div>

                    {/* <div className='col-6 d-flex justify-content-end'>
                        <p className='text-danger bold mt-2 mb-0' ><Link passHref href={link}><a className='text-danger fs-12'> Click to view the review </a></Link></p>
                    </div> */}

                </div>
            </div>
        </div>
    )

}

export default CarouselItem