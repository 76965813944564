import Link from "next/link";
import Image from "next/image";

export const DownloadAppFooter = () => {
  return (
    <div
      className="text-center py-5  bg-danger themed-bg d-lg-flex justify-content-center align-items-center"
      style={{ minHeight: 200 }}
    >
      <div>
        <h1 className="bold text-white">Join the evolution of car care.</h1>
        <p className="text-white">Download The Paleo Car Care App Today!</p>
        <ul className="app-stores list-unstyled list-inline mx-auto mt-5 d-inline-block">
          <li className="list-inline-item mr-3" style={{ width: 200 }}>
            <Link
              href={"https://apps.apple.com/us/app/paleo-car-care/id1552154146"}
            >
              <a target="_blank">
                <Image
                  className="ios img-fluid"
                  src="/images/appstore-apple.svg"
                  alt="app-store"
                  width="100vw"
                  height="30%"
                  layout={"responsive"}
                  loading={"lazy"}
                />
              </a>
            </Link>
          </li>
          <li className="list-inline-item" style={{ width: 200 }}>
            <Link
              href={
                "https://play.google.com/store/apps/details?id=com.paleo.customer"
              }
            >
              <a target="_blank">
                <Image
                  className="android img-fluid"
                  src="/images/appstore-android.svg"
                  alt="google play"
                  width="100vw"
                  height="30%"
                  layout={"responsive"}
                  loading={"lazy"}
                />
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
